import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../slices/apiSlice";
import authSlice from "../slices/authSlice/authSlice";
import forgotSlice from "../slices/forgotSlice/forgotSlice";
import passwordSlice from "../slices/passwordSlice/passwordSlice";
import menuSlice from "../slices/MenuSlice/menuSlice";
import trackingSlice from "../slices/trackingSlice/trackingSlice";
import { dataTrackingSlice } from "../slices/trackingSlice/trackingApiSlice";
import { menuApliSlice } from "../slices/MenuSlice/menuApliSlice";
import consultSlice from "../slices/consultSlice/consultSlice";
import exportExcSlice from "../slices/exportExcSlice/exportExcSlice";
import usuariosSlice from "../slices/Paramgen/Usuarios/usuariosSlice";
import errorsSlice from "../slices/Errorsxx/errorsSlice";
import confirmSlice from "../slices/Confirmx/confirmSlice";
import providerSlice from "../slices/Pfinanci/Provider/providerSlice";
import ofercomeSlice from "../slices/Financix/ofercomeSlice";
import fichacliSlice from "../slices/Modclixx/Fichacli/fichacliSlice";
import fichprovSlice from "../slices/Modclixx/Fichprov/fichprovSlice";
import anticipoSlice from "../slices/Modimpxx/Anticipo/anticipoSlice";
import tareasxxSlice from "../slices/Ordtraxx/Tareasxx/tareasxxSlice";
import trackimpSlice from "../slices/Modimpxx/Trackimp/trackimpSlice";

//Redux persist
import storageSession from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import thunk from "redux-thunk";
import centcostSlice from "../slices/Pfinanci/Centcost/centcostSlice";
import genioxxxSlice from "../slices/Genioxxx/genioxxxSlice";
import { genioxxxApiSlice } from "../slices/Genioxxx/genioxxxApiSlice";
import notificxSlice from "../slices/NotificxSlice/notificxSlice";
import graficsxSlice from "../slices/Graficsx/graficsxSlice";
import saldoctrSlice from "../slices/Doctraxx/Saldoctr/saldoctrSlice";
import reslegxxSlice from "../slices/Paraduax/Reslegxx/reslegxxSlice";
import producxxSlice from "../slices/Producxx/producxxSlice";
import resdocuxSlice  from "../slices/Modimpxx/Resdocux/resdocuxSlice";
import doclientSlice  from "../slices/Modclixx/Doclient/doclientSlice";
import tracscrlSlice  from "../slices/Tracscrl/tracscrlSlice";

const persistConfig = {
  key: "auth",
  storage: storageSession,
};

const graphPersistConfig = {
  key: "graph",
  storage: storageSession,
};
const persistedReducer = persistReducer(persistConfig, authSlice);

const graphPersistReducer = persistReducer(graphPersistConfig, graficsxSlice);

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [genioxxxApiSlice.reducerPath]: genioxxxApiSlice.reducer,
    persistedReducer,
    graphPersistReducer,
    forgot: forgotSlice,
    password: passwordSlice,
    menu: menuSlice,
    tracking: trackingSlice,
    consult: consultSlice,
    exportexcl: exportExcSlice,
    usuarios: usuariosSlice,
    errors: errorsSlice,
    confirm: confirmSlice,
    provider: providerSlice,
    ofercome: ofercomeSlice,
    fichacli: fichacliSlice,
    fichprov: fichprovSlice,
    centcost: centcostSlice,
    genioxxx: genioxxxSlice,
    anticipo: anticipoSlice,
    notificx: notificxSlice,
    tareasxx: tareasxxSlice,
    saldoctr: saldoctrSlice,
    reslegxx: reslegxxSlice,
    producxx: producxxSlice,
    trackimp: trackimpSlice,
    resdocux: resdocuxSlice,
    doclient: doclientSlice,
    tracscrl: tracscrlSlice,
  },
  devTools: process.env.REACT_APP_CIMA_DEBUG,
  middleware: [thunk, dataTrackingSlice.middleware, menuApliSlice.middleware, genioxxxApiSlice.middleware],
});

export const persistor = persistStore(store);
