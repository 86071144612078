import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/loginCima",
        method: "POST",
        body: {
          USRIDXXX: credentials.name,
          USRPASXX: credentials.password,
          SISTEMAX: "CIMA",
        },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "GET",
      }),
    }),
    userInfo: builder.query({
      query: () => `/userinfo`,
    }),
    loginUserExternal: builder.mutation({
      query: (params) => ({
        url: "/loginCima",
        method: "POST",
        body: {
          USRIDXXX: params.USRIDXXX,
          USRPASXX: params.USRIDXXX,
          SISTEMAX: "CIMA",
          EXTERNAL: true,
        },
      }),
    }),

    getPermissionUserReplace: builder.mutation({
      query: (parameters) => ({
        url: `/getPermissionUserReplace/${parameters.USRIDXXX}`,
        method: "GET",
        headers: { Modulecima: "usuarios" },
      }),
    }),
    getUserTemporal: builder.query({
      query: (parameters) => ({
        url: `/getUserTemporal/${parameters.USRIDXXX}`,
        method: "GET",
        headers: { Modulecima: "usuarios" },
      }),
    }),
    getDashboard: builder.query({
      query: () => ({
        url: `/Usuarios/Dashboar`,
        method: "GET",
        headers: { Modulecima: "usuarios" },
      }),
    }),
    decryptUrl: builder.query({
      query: (parameters) => ({
        url: `/Decrypxx`,
        method: "GET",
        params: { query: parameters },
      }),
    }),
  }),
});

export const authApiTokenSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginToken: builder.mutation({
      query: (credentials) => ({
        url: "/loginCimaCode",
        method: "POST",
        body: {
          USRIDXXX: credentials.USRIDXXX,
          USRTOKXX: credentials.userCode,
          SISTEMAX: "CIMA",
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useUserInfoQuery,
  useLazyUserInfoQuery,
  useGetPermissionUserReplaceMutation,
  useGetUserTemporalQuery,
  useLoginUserExternalMutation,
  useGetDashboardQuery,
  useDecryptUrlQuery,
} = authApiSlice;
export const { useLoginTokenMutation } = authApiTokenSlice;
