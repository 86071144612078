/**
 * Slice para el maneja del estado para el componente Ficha cliente
 */
import { createSlice } from "@reduxjs/toolkit";

export const defaultValuesDocument = {
  MODIDXXX: "",
  PROIDXXX: "",
  AREAIDXX: "",
  AREADESX: "",
  SERIEIDX: "",
  SERIEDES: "",
  TIPOIDXX: "",
  TIPODESX: "",
};

const initialState = {
  types: {},
  series: {},
};

const doclientSlice = createSlice({
  name: "doclient",
  initialState: initialState,
  reducers: {
    setTypes: (state, action) => {
      const { area, serie, types } = action.payload;
      const copyTypes = { ...state.types };
      copyTypes[area] = { ...state.types[area] };
      copyTypes[area][serie] = types;
      state.types = copyTypes;
    },
    setSeries: (state, action) => {
      const { area, series } = action.payload;
      const copyTypes = { ...state.series };
      copyTypes[area] = series;
      state.series = copyTypes;
    },
  },
});

export const { setTypes, setSeries } = doclientSlice.actions;

export default doclientSlice.reducer;

export const getAllStateDoclient = (state) => {
  return state.doclient;
};
