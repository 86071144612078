/**
 * Slice para el manejo del estado Anticipo cliente
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalUsd: 0,
  totalTributes: 0,
  totalGeneral: 0,
  totalThird: 0,
  totalOwn: 0,
  dataClient: {},
  dataPDF: {},
  VALUEUSD: [{ ANTFOBXX: 0, ANTFLETE: 0, ANTSEGXX: 0, ANTOTROS: 0, ANTAJUST: 0, LIMACEXX: "", ANTTOTAL: 0 }],
  VALUETBT: [
    {
      ANTGRVMX: 0,
      ANTIVAXX: 0,
      ANTSALVX: 0,
      ANTCOMPX: 0,
      ANTDUMPX: 0,
      ANTSANCX: 0,
      ANTRESCT: 0,
      LIMACEXX: "",
      ANTTOTAL: 0,
    },
  ],
  trm: 0,
};

const anticipoSlice = createSlice({
  name: "anticipo",
  initialState: initialState,
  reducers: {
    setTotalUsd: (state, action) => {
      state.totalUsd = action.payload;
      // state.totalGeneral = state.totalUsd + state.totalTributes + state.totalThird + state.totalOwn;
    },
    setTotalTributes: (state, action) => {
      state.totalTributes = action.payload;
      state.totalGeneral = state.totalTributes + state.totalThird + state.totalOwn;
    },
    setTotalThird: (state, action) => {
      state.totalThird = action.payload;
      state.totalGeneral = state.totalTributes + state.totalThird + state.totalOwn;
    },
    setTotalOwn: (state, action) => {
      state.totalOwn = action.payload;
      state.totalGeneral = state.totalTributes + state.totalThird + state.totalOwn;
    },
    setDataClient: (state, action) => {
      state.dataClient = action.payload;
    },
    setDataPDF: (state, action) => {
      state.dataPDF = action.payload;
    },
    //Setear individualmente Valor en Aduanas USD
    setValueUsd: (state, action) => {
      const { index, input, value } = action.payload;
      const copyObject = [...state.VALUEUSD];
      copyObject[index] = { ...copyObject[index], [input]: parseFloat(value) };
      state.VALUEUSD = copyObject;
    },
    setMasiveValueUsd: (state, action) => {
      state.VALUEUSD = action.payload;
    },
    resetState: (state) => initialState,
    //Setear individualmente Valor de los tributos
    setValueTribute: (state, action) => {
      const { index, input, value } = action.payload;
      const copyObject = [...state.VALUETBT];
      copyObject[index] = { ...copyObject[index], [input]: parseFloat(value) };
      state.VALUETBT = copyObject;
    },
    setMasiveValueTribute: (state, action) => {
      state.VALUETBT = action.payload;
    },
    setTRM: (state, action) => {
      state.trm = action.payload;
    },
  },
});

export const {
  setTotalUsd,
  setTotalTributes,
  setTotalThird,
  setTotalOwn,
  setDataClient,
  setDataPDF,
  setValueUsd,
  setMasiveValueUsd,
  resetState,
  setValueTribute,
  setMasiveValueTribute,
  setTRM,
} = anticipoSlice.actions;

export default anticipoSlice.reducer;

export const getTotalGeneral = (state) => {
  return state.anticipo.totalGeneral;
};
export const getDataClient = (state) => {
  return state.anticipo.dataClient;
};
export const getDataPDF = (state) => {
  return state.anticipo.dataPDF;
};

export const getAllValues = (state) => {
  return state.anticipo;
};

export const getTRM = (state) => {
  return state.anticipo.trm;
};
